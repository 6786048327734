<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="已铺市店铺" name="first"></el-tab-pane>
          <el-tab-pane label="未铺市店铺" name="nofirst"></el-tab-pane>
        </el-tabs>
        <el-descriptions>
          <template slot="extra">
            <el-button type="primary" @click="handlerexdolw">导出</el-button>
          </template>
          <el-descriptions-item label="产品名称">
            {{ tabledata.goods_name }}
          </el-descriptions-item>
          <el-descriptions-item label="品牌">
            {{ tabledata.brand_name }}
          </el-descriptions-item>
          <el-descriptions-item label="地区">
            {{ tabledata.area_name }}
          </el-descriptions-item>
          <el-descriptions-item label="业务员">
            {{ tabledata.user_name }}
          </el-descriptions-item>
          <el-descriptions-item label="网点数">
            {{ tabledata.num }}
          </el-descriptions-item>
          <el-descriptions-item label="网点总量">
            {{ tabledata.total_num }}
          </el-descriptions-item>
          <el-descriptions-item label="铺市率">
            {{ tabledata.rate }}
          </el-descriptions-item>
        </el-descriptions>
        <el-table :data="tabledata.list" stripe style="width: 100%">
          <el-table-column
            v-for="(item, tableIndex) in Columns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
        <el-pagination
          :current-page="form.page"
          :layout="layout"
          :page-size="form.limit"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { downloadFile, post_ } from '@/api/Employee'

  export default {
    name: 'Markerdiv',
    props: {
      title: {
        type: String,
        default: '详情',
      },
    },
    data() {
      return {
        showDialog: false,
        activeName: 'first',
        type: 1,
        detail: 1, //组件内区分是品牌还是商品 1品牌 2商品
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          start_time: '',
          end_time: '',
          area_id: '',
          depart_id: '',
          user_id: '',
          brand_id: '',
          goods_id: '',
          page: 1,
          limit: 10,
        },
        tabledata: {},
        Columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '联系人',
            prop: 'boss',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '地址',
            prop: 'address',
            width: '',
          },
        ],
      }
    },
    watch: {
      activeName(v) {
        if (v == 'first') {
          this.type = 1
        } else {
          this.type = 0
        }
        if (this.detail == 1) {
          this.handlerbrandinfo()
        } else {
          this.handlergoodsinfo()
        }
      },
    },
    methods: {
      handlerbrandinfo() {
        var url
        if (this.type) {
          url = '/reportAdmin/analysis/market-brand-detail'
        } else {
          url = '/reportAdmin/analysis/market-brand-detail-no'
        }
        post_(url, this.form).then((res) => {
          console.log(res)
          this.tabledata = res.data
          this.totalCount = res.totalCount
        })
      },
      handlergoodsinfo() {
        var url
        if (this.type) {
          url = '/reportAdmin/analysis/market-goods-detail'
        } else {
          url = '/reportAdmin/analysis/market-goods-detail-no'
        }
        post_(url, this.form).then((res) => {
          console.log(res)
          this.tabledata = res.data
          this.totalCount = res.totalCount
        })
      },
      handlerexdolw() {
        var url, name
        if (this.detail == 1) {
          url = 'reportAdmin/analysis/market-brand-detail-export'
          name = '铺市率按品牌.xlsx'
        } else {
          url = 'reportAdmin/analysis/market-goods-detail-export'
          name = '铺市率按商品.xlsx'
        }
        downloadFile(url, name, this.form)
      },
      // 分页
      handleCurrentChange(val) {
        this.form.page = val
        if (this.detail == 1) {
          this.handlerbrandinfo()
        } else {
          this.handlergoodsinfo()
        }
      },
      handleSizeChange(val) {
        this.form.limit = val
        if (this.detail == 1) {
          this.handlerbrandinfo()
        } else {
          this.handlergoodsinfo()
        }
      },
    },
  }
</script>

<style scoped></style>
