var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.showDialog,
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "已铺市店铺", name: "first" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "未铺市店铺", name: "nofirst" },
                  }),
                ],
                1
              ),
              _c(
                "el-descriptions",
                [
                  _c(
                    "template",
                    { slot: "extra" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerexdolw },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                  _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.goods_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "品牌" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.brand_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "地区" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.area_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "业务员" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.user_name) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "网点数" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.num) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "网点总量" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.total_num) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "铺市率" } }, [
                    _vm._v(" " + _vm._s(_vm.tabledata.rate) + " "),
                  ]),
                ],
                2
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tabledata.list, stripe: "" },
                },
                _vm._l(_vm.Columns, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: "",
                      align: "center",
                    },
                  })
                }),
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.form.page,
                  layout: _vm.layout,
                  "page-size": _vm.form.limit,
                  total: _vm.totalCount,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }